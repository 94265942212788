class SliderTopProductWidgetHandler extends elementorModules.frontend.handlers
    .Base {
    updateSlider() {
        const currentEle = this.$element[0]
        const sliderEle = currentEle.querySelector('.slider-top-product__list')

        if (currentEle && sliderEle) {
            new Swiper(sliderEle, {
                slidesPerView: 5,
                spaceBetween: 8,
                navigation: {
                    prevEl: currentEle.querySelector(
                        '.slider-top-product__header .navigation__slider .prev button'
                    ),
                    nextEl: currentEle.querySelector(
                        '.slider-top-product__header .navigation__slider .next button'
                    ),
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                    },
                    992: {
                        slidesPerView: 5,
                        spaceBetween: 8,
                    },
                },
            })
        }
    }

    onInit() {
        this.updateSlider()
    }
}

window.addEventListener('elementor/frontend/init', () => {
    elementorFrontend.elementsHandler.attachHandler(
        'hiqua_slider_top_product',
        SliderTopProductWidgetHandler
    )
})
